import React from "react";
import Thought from "../components/Thought";
import ThoughtOpen from "../components/ThoughtOpen";
import { thoughts } from "../data";
import {useState } from 'react'


export default function ThoughtList() {
    let [isOpen, setIsOpen] = useState(false)
    return (
        <section id="thoughts">
            <div className="static container flex flex-wrap py-10 mx-auto text-center lg:px-40">
            {thoughts.map((thought) => (
                    <Thought thought/>
        ))}
            <Thought onClick={() => setIsOpen(true)}/>
            <ThoughtOpen open={isOpen} close={() => setIsOpen(false)}/>
            </div>
        </section>
    );
} 