export const projects = [
  {
    title: "Setlist Creator",
    subtitle: "Vue.js",
    description:
      "A Web Application that receives Spotify playlists and turns them into setlists for DJs",
    image: "./setlistcreator.gif",
    link: "https://github.com/Danny6034/setlist-creator",
  },
  {
    title: "Pokedex App",
    subtitle: "JavaScript + HTML/CSS",
    description:
      "A study of the development of a basic Pokedex app with pure JavaScript, HTML and CSS",
    image: "./pokedex.gif",
    link: "https://github.com/Danny6034/Simple-Pokedex",
  },
  {
    title: "Ravenous",
    subtitle: "React",
    description:
      "A Yelp Clone App built with React.js that makes API requests to the official Yelp API. Includes filtering and sorting searches",
    image: "./ravenous.gif",
    link: "https://github.com/Danny6034/ravenous",
  },
  {
    title: "Artist Sucess Forecasting Model",
    subtitle: "R",
    description:
      "A personal project in which I attempt to use Spotify Charts data to predict future success of a specific artist",
    image: "./forecastmodel.gif",
    link: "https://github.com/Danny6034/Forecast-Modelling-Exploration/tree/main",
  },
];

export const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Jesse Hicks",
    company: "Zoozle",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "Ruben Alvarez",
    company: "Dooble.io",
  },
];

export const skills = [
  "JavaScript",
  "React & Vue.Js",
  "PHP",
  ".NET/C#",
  "RESTful APIs",
  "Spring Boot",
];

export const thoughts = [
  {
    id:1,
    title: "JAVASCRIPT",
    body: `JavaScript is the world most popular
    lightweight, interpreted compiled programming
    language. It is also known as scripting
    language for web pages. It is well-known for
    the development of web pages, many non-browser
    environments also use it. JavaScript can be
    used for Client-side developments as well as
    Server-side developments. JavaScript is the world most popular
    lightweight, interpreted compiled programming
    language. It is also known as scripting
    language for web pages. It is well-known for
    the development of web pages, many non-browser
    environments also use it. JavaScript can be
    used for Client-side developments as well as
    Server-side developments. JavaScript is the world most popular
    lightweight, interpreted compiled programming
    language. It is also known as scripting
    language for web pages. It is well-known for
    the development of web pages, many non-browser
    environments also use it. JavaScript can be
    used for Client-side developments as well as
    Server-side developments. JavaScript is the world most popular
    lightweight, interpreted compiled programming
    language. It is also known as scripting
    language for web pages. It is well-known for
    the development of web pages, many non-browser
    environments also use it. JavaScript can be
    used for Client-side developments as well as
    Server-side developments`,
    author: "Nishant Singh ",
    imgUrl:
      "https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png",
    },
    {
    id:2,
    title: "Data Structure ",
    body: `There are many real-life examples of
    a stack. Consider an example of plates stacked
    over one another in the canteen. The plate
    which is at the top is the first one to be
    removed, i.e. the plate which has been placed
    at the bottommost position remains in the
    stack for the longest period of time. So, it
    can be simply seen to follow LIFO(Last In
    First Out)/FILO(First In Last Out) order.`,
    author: "Suresh Kr",
    imgUrl:
      "https://media.geeksforgeeks.org/img-practice/banner/coa-gate-2022-thumbnail.png",
    },
    {
    id:3,
    title: "Algorithm",
    body: `The word Algorithm means “a process
    or set of rules to be followed in calculations
    or other problem-solving operations”. Therefore
    Algorithm refers to a set of rules/instructions
    that step-by-step define how a work is to be
    executed upon in order to get the expected
    results. `,
    author: "Monu Kr",
    imgUrl:
      "https://media.geeksforgeeks.org/img-practice/banner/google-test-series-thumbnail.png",
    },
    {
    id:4,
    title: "Computer Network",
    body: `An interconnection of multiple devices,
    also known as hosts, that are connected using
    multiple paths for the purpose of sending/
    receiving data media. Computer networks can
    also include multiple devices/mediums which
    help in the communication between two different
    devices; these are known as Network devices
    and include things such as routers, switches,
    hubs, and bridges. `,
    author: "Sonu Kr",
    imgUrl:
      "https://media.geeksforgeeks.org/img-practice/banner/cp-maths-java-thumbnail.png",
    },
]
