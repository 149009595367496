
import Switcher from './Switcher'
import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";
import { Outlet, Link } from "react-router-dom";

export default function Navbar() {
  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a className="title-font font-medium text-white mb-4 md:mb-0">
          <a href="/#about" className="ml-3 text-xl">
            Daniel Leite
          </a>
        </a>
        <nav className="text-white dark:text-gray-400 md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center">
          <a href="/#projects" className="mr-5 hover:text-white">
            Past Work
          </a>
          <a href="/#skills" className="mr-5 hover:text-white">
            Skills
          </a>
          {/* <a href="#testimonials" className="mr-5 hover:text-white">
            Testimonials
          </a> */} 
          <div class="bg-gray-800 rounded-xl"><a href="https://github.com/Danny6034" rel="noopener noreferrer" target="_blank"><img src="./github-mark-white.png" className="h-8 hover:opacity-25 rounded-xl" alt="github icon"/></a></div>
        </nav>

        {/*<a className="text-white dark:text-gray-400 flex mr-5 hover:text-white justify-center items-center md:flex-col">
            <Link to={'/streamofconsciousness'}>Stream of Consciousness</Link>
          </a>*/}
        <Switcher/>
        <a
          href="#contact"
          className="text-white dark:text-gray-400 inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0">
          Hire Me
          <ArrowRightIcon className="w-4 h-4 ml-1" />
        </a>
      </div>
    </header>
  );
}