import React, { useState } from 'react';
import useDarkSide from '../hooks/useDarkSide';
import Toggle from "react-toggle";
import "react-toggle/style.css"
import { HiSun } from "react-icons/hi";
import { FaMoon } from "react-icons/fa";

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(colorTheme === 'light' ? true : false);


  const toggleDarkMode = checked => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <div>
    <Toggle
    checked={colorTheme === 'light'}
    onChange={toggleDarkMode}
    icons={{ 
        checked: 
        <FaMoon
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          color: 'black'
        }}/>  , 
        unchecked: 
        <HiSun
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          color: 'yellow'
        }}/> 
    }}
    aria-label="Dark mode toggle"
    />
    </div>
  );
}