import React from 'react';
import { useRouteError } from "react-router-dom";


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  if (error.status == 404) {
    return (
      <div id="error-page" className="flex flex-col min-h-screen justify-center items-center width-100">
        <h1 className="title-font">Oops!</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
        <p>
          <i>{ error.status}</i>
        </p>
        <img className="w-1/2"src="./404-error-img.png"/>
      </div>
    );
  }



}
