import React from "react";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import ThoughtList from "../components/ThoughtList"


export default function Blog() {
    return (
      <main className="bg-gray-100 text-black dark:text-gray-400 dark:bg-gray-900 body-font">
        <Navbar/>
        <ThoughtList/>
        <Contact/>
      </main>
    );
  }