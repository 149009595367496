import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import Blog from './pages/Blog';
import ErrorPage from './pages/error-page';

//Root Router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/streamofconsciousness",
    element: <Blog/>
  },
  {
    path: "/streamofconsciousness/:blogId",
    element: <Blog/>
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);
