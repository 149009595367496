import React from "react";
import { thoughts } from "../data";
import { Fragment, useState } from 'react';



export default function Thought(thought) {

  let [isOpen, setIsOpen] = useState(true)


  function openModal() {
    setIsOpen(true)
  }

  
  return (
    <div class="flex flex-wrap display-flex justify-center" onClick={openModal}>
        <a class="m-4 flex  p-4 w-2/5 items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src={thought.imgUrl} alt=""/>
            <div class="flex flex-col justify-between text-left p-4 leading-normal">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{thought.title}</h5>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">lorem ipsum moment</p>
            </div>
        </a>
    </div>
  );
}